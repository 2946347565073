import { Box } from "components/shared/box"
import { Button } from "components/shared/landing/button"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

export default function FourOhFour() {
  return (
    <Box center full className="flex-1 gap-10">
      <Text className="text-h3">404 - Page Not Found</Text>
      <Link href="/">
        <Button>Go back home</Button>
      </Link>
    </Box>
  )
}
